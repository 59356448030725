import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row, config } from "react-awesome-styled-grid"
import Layout from "../../components/Layout"

import { Hero, HeroContent, HeroHeading } from "../../components/Hero"
import BackgroundImage from "gatsby-background-image"
import DivHelper from "../../components/DivHelper"
import { HoverButton, TrackedLink } from "../../components/Button"
import styled from "styled-components"

import { Heading2 } from "../../components/Heading"

import { FaStar } from "@react-icons/all-files/fa/FaStar"
import { FaStarHalfAlt } from "@react-icons/all-files/fa/FaStarHalfAlt"

import { ArrowButton } from "../../components/Button"

import { TableOfContents } from "../../components/TableOfContents"
import { IconCheck, IconCross } from "../../components/Icons"
import Block from "../../components/Block"

const NoRecommendation = styled.span`
  color: #db3b3b;
  font-weight: 600;
  text-align: center;
`

const TableWrapper = styled.div`
  overflow-x: auto;
`

const Table = styled.table`
  user-select: none;
  border: none;
  outline: none;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0 auto;
  width: 800px;

  &,
  li {
    font-size: 0.9rem;
    font-weight: 400;
  }

  th,
  td {
    padding: 15px 10px;
    background-color: #fff;
  }

  th {
    hyphens: auto;
    text-align: left;
    width: 100px;
    position: sticky;
    left: 0px;
  }

  tr:nth-child(even) > th,
  tr:nth-child(even) > td {
    background-color: #d9ebfc;
  }

  td {
    width: auto;
  }

  @media (min-width: 576px) {
    th {
      width: 150px;
    }
  }

  @media (min-width: 768px) {
    th {
      width: 175px;
    }
  }

  @media (min-width: 992px) {
    width: 1000px;

    th {
      width: 200px;
    }
  }

  @media (min-width: 1200px) {
    width: 1190px;

    th {
      width: 250px;
    }
  }
`

const TdCenter = styled.td`
  text-align: center;
`

const StyledRating = styled.div`
  font-size: 1.2rem;
  color: #ffbb00;
`

const Rating = ({ rating }) => {
  const stars = Math.floor(rating)
  const halfStar = rating % 1 > 0

  return (
    <StyledRating>
      {[...Array(stars)].map(i => (
        <FaStar key={i} />
      ))}
      {halfStar && <FaStarHalfAlt />}
    </StyledRating>
  )
}

const Background = ({ className, children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "stock/romantic-hero.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <BackgroundImage
      className={className}
      fluid={data.image.childImageSharp.fluid}
      style={{ height: "100%" }}
    >
      {children}
    </BackgroundImage>
  )
}

const WinnerBox = styled(Row)`
  border-radius: 4px;
  border: 5px solid
    ${props => (props.red ? "#ec5d5d" : props.yellow ? "#fdff61" : "#58cb53f7")};
`

const WinnerHighlight = styled(Col)`
  background: ${props =>
    props.red ? "#ec5d5d" : props.yellow ? "#fdff61" : "#58cb53f7"};
  text-align: center;
  color: #000;

  p {
    text-align: center;
  }
`

const ContentWrapper = styled.div`
  padding: 15px;
`

const Paragraph = styled.p`
  ${props => props.m0 && "margin-bottom: 0;"}
  ${props =>
    props.right &&
    config(props).media["md"]`
    text-align: right;
  `}
`

const ColoredLink = styled.a`
  background-color: ${props => props.color};
`

const ProductHeadingWrapper = styled.div`
  ${props => props.desktop && "display: none;"}
  text-align: center;
  margin-bottom: 15px;

  ${props => config(props).media["md"]`
    display: ${props => (props.desktop ? "block" : "none")};
  `}
`

const StyledProductHeading = styled.h3`
  position: relative;
  display: inline-block;
  font-size: 1.3rem;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 15px;

  &::after {
    position: absolute;
    content: "";
    left: 50%;
    width: 85%;
    height: 1px;
    bottom: -10px;
    transform: translateX(-50%);
    background: #000;
  }
`

const SeparatorLine = styled.div`
  width: ${props => (props.small ? 50 : 75)}%;
  height: 2px;
  background: #666;
  margin: ${props => (props.small ? 0 : 100)}px auto 0 auto;
`

const DateRight = styled.p`
  text-align: right;
`

const CustomUl = styled.ul`
  margin: 0;
  padding: 0 0 0 1.5rem;
`

const CustomLi = styled.li`
  text-align: left;
  list-style: "${props => props.bullet} ";
  list-style-position: outside;
`

const ProductHeading = ({ rating, desktop = false, children }) => {
  return (
    <ProductHeadingWrapper desktop={desktop}>
      <StyledProductHeading>{children}</StyledProductHeading>
      <div>
        <Rating rating={rating} />
      </div>
    </ProductHeadingWrapper>
  )
}

const Quote = styled.p`
  text-align: center;
  font-style: italic;
`

const RowMargin = styled(Row)`
  margin-top: ${props => props.margin || 20}px;
`

const BackgroundBeige = styled.div`
  background-color: #f0f0f0;
  margin-top: 40px;
  padding: 20px 0 40px 0;
`

const ImageWrapper = styled.div`
  padding: 30px;

  picture,
  img {
    border-radius: 50%;
  }
`

const ColMarginMobile = styled(Col)`
  margin-top: 20px;
  ${props => config(props).media["xl"]`
    margin-top: 0px;
  `}
`

const ColMargin = styled(Col)`
  margin-top: ${props => props.mt || 0}px;
  margin-bottom: ${props => props.mb || 0}px;
`

const Heading3Margin = styled.h3`
  margin-bottom: 10px;
`

const CbdGleitgel = ({ location }) => {
  return (
    <Layout
      title="CBD Gleitgel Vergleich"
      location={location}
      description="CBD Gleitgele im Test & Vergleich 2022/ 2023: ✔️ Beste CBD Wirkung ✔️ Beste Gleitfähigkeit ✔️  Günstigster Preis"
      comments={true}
      light
      mt0
    >
      <Hero heightVh={30}>
        <Background>
          <HeroContent align="flex-end">
            <Container>
              <Row>
                <Col>
                  <HeroHeading>CBD Gleitgel</HeroHeading>
                </Col>
              </Row>
            </Container>
          </HeroContent>
        </Background>
      </Hero>
      <Container>
        <Row>
          <Col>
            <TableWrapper>
              <Table cellSpacing={0} cellPadding={0} id="vergleichstabelle">
                <tr>
                  <th aria-label="Produktbild"></th>
                  <TdCenter>
                    <StaticImage
                      src="../../assets/images/products/cbd-gleitgel/joy.png"
                      alt="Joy CBD Gleitgel"
                      layout="constrained"
                      placeholder="blurred"
                      width={275}
                    />
                  </TdCenter>
                  <TdCenter>
                    <StaticImage
                      src="../../assets/images/products/cbd-gleitgel/shots.png"
                      alt="Shots CBD Gleitgel"
                      layout="constrained"
                      placeholder="blurred"
                      width={275}
                    />
                  </TdCenter>
                  <TdCenter>
                    <StaticImage
                      src="../../assets/images/products/cbd-gleitgel/mian.png"
                      alt="Mian CBD Gleitgel"
                      layout="constrained"
                      placeholder="blurred"
                      width={275}
                    />
                  </TdCenter>
                </tr>
                <tr>
                  <th aria-label="Kauf Button"></th>
                  <TdCenter>
                    <HoverButton
                      href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2Fjoy-cbd-gleitgel%2F"
                      category="cbd-gleitgel"
                      action="hanfgefluester"
                      label="table-top"
                    >
                      Hier günstig kaufen
                      <img
                        src="https://t.adcell.com/p/view?promoId=297834&slotId=98699"
                        border="0"
                        width="1"
                        height="1"
                        alt=""
                      />
                    </HoverButton>
                  </TdCenter>
                  <TdCenter>
                    <HoverButton
                      href="https://www.amazon.de/Pharmquest-Shots-Cannabis-Gleitgel-Wasserbasis/dp/B07QWLFFTH"
                      category="cbd-gleitgel"
                      action="shots"
                      label="table-top"
                    >
                      Hier günstig kaufen
                    </HoverButton>
                  </TdCenter>
                  <TdCenter>
                    <NoRecommendation>Keine Empfehlung</NoRecommendation>
                  </TdCenter>
                </tr>
                <tr>
                  <th>Produkt</th>
                  <td>Joy CBD Gleitgel</td>
                  <td>Shots PHA139 CBD Gleitmittel</td>
                  <td>mian Premium CBD Lube</td>
                </tr>
                <tr>
                  <th>CBD Gleitgel Test</th>
                  <td>
                    <Rating rating={5} />
                  </td>
                  <td>
                    <Rating rating={2.5} />
                  </td>
                  <td>
                    <Rating rating={1} />
                  </td>
                </tr>
                <tr>
                  <th>CBD Gleitgel Preis</th>
                  <td>19,95 €</td>
                  <td>20,17 €</td>
                  <td>25,99 €</td>
                </tr>
                <tr>
                  <th>Anbieter</th>
                  <td>
                    Hanfgeflüster
                    <br />
                    (aus Deutschland)
                  </td>
                  <td>
                    Amazon
                    <br />
                    (aus Niederlande)
                  </td>
                  <td>
                    Amazon
                    <br />
                    (aus China)
                  </td>
                </tr>
                <tr>
                  <th>Füllmenge</th>
                  <td>50 ml</td>
                  <td>50 ml</td>
                  <td>50 ml</td>
                </tr>
                <tr>
                  <th>Basis</th>
                  <td>Wasserbasis</td>
                  <td>Wasserbasis</td>
                  <td>Wasserbasis</td>
                </tr>
                <tr>
                  <th>CBD Gehalt pro Flasche</th>
                  <td>
                    50 mg
                    <br />
                    (sehr hoch)
                  </td>
                  <td>Unbekannt</td>
                  <td>
                    10 mg
                    <br />
                    (niedrig)
                  </td>
                </tr>
                <tr>
                  <th>CBD Gleitgel Wirkung</th>
                  <td>Sehr gut</td>
                  <td>Mittelmäßig</td>
                  <td>Schlecht</td>
                </tr>
                <tr>
                  <th>Eignung</th>
                  <td>
                    Vaginal
                    <br />
                    Anal
                    <br />
                    Oral
                    <br />
                    Massage
                  </td>
                  <td>
                    Vaginal
                    <br />
                    Anal
                    <br />
                    Oral
                    <br />
                    Massage
                  </td>
                  <td>
                    Vaginal
                    <br />
                    Anal
                    <br />
                    Massage
                  </td>
                </tr>
                <tr>
                  <th>Verträglichkeit auf der Haut (pH-optimiert)</th>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCross />
                  </td>
                </tr>
                <tr>
                  <th>Vegan</th>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCross />
                  </td>
                  <td>
                    <IconCross />
                  </td>
                </tr>
                <tr>
                  <th>Geschmack und Geruch</th>
                  <td>Geschmack- und geruchlos</td>
                  <td>Geruchlos, bitterer Geschmack</td>
                  <td>Geruchlos, stark bitterer Geschmack</td>
                </tr>
                <tr>
                  <th>CBD Zertifikat</th>
                  <td>TÜV Süd</td>
                  <td>Unbekannt</td>
                  <td>Unbekannt</td>
                </tr>
                <tr>
                  <th>Lieferzeit</th>
                  <td>1-2 Tage + 2,95 € Versand</td>
                  <td>3-5 Tage + 3,99 € Versand</td>
                  <td>12 Tage + 3,99 € Versand</td>
                </tr>
                <tr>
                  <th>Verpackungsart</th>
                  <td>Recycelt</td>
                  <td>Unbekannt</td>
                  <td>Unbekannt</td>
                </tr>
                <tr>
                  <th>Hinweise</th>
                  <td>
                    <CustomUl>
                      <CustomLi bullet="👨‍🔬">Dermatologisch getestet</CustomLi>
                      <CustomLi bullet="💧">Hohe Gleitfähigkeit</CustomLi>
                      <CustomLi bullet="📦">
                        Kostenlose Lieferung ab 30,00&nbsp;€
                      </CustomLi>
                    </CustomUl>
                  </td>
                  <td>
                    <CustomUl>
                      <CustomLi bullet="❌">Dünnflüssig</CustomLi>
                      <CustomLi bullet="📦">Spar-Abo möglich</CustomLi>
                    </CustomUl>
                  </td>
                  <td>
                    <CustomUl>
                      <CustomLi bullet="❌">Trocknet schnell</CustomLi>
                      <CustomLi bullet="❌">Sehr klebrig</CustomLi>
                    </CustomUl>
                  </td>
                </tr>
                <tr>
                  <th aria-label="Kauf Button"></th>
                  <TdCenter>
                    <HoverButton
                      href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2Fjoy-cbd-gleitgel%2F"
                      category="cbd-gleitgel"
                      action="hanfgefluester"
                      label="table-bottom"
                    >
                      Hier günstig kaufen
                    </HoverButton>
                  </TdCenter>
                  <TdCenter>
                    <HoverButton
                      href="https://www.amazon.de/Pharmquest-Shots-Cannabis-Gleitgel-Wasserbasis/dp/B07QWLFFTH"
                      category="cbd-gleitgel"
                      action="shots"
                      label="table-bottom"
                    >
                      Hier günstig kaufen
                    </HoverButton>
                  </TdCenter>
                  <TdCenter>
                    <NoRecommendation>Keine Empfehlung</NoRecommendation>
                  </TdCenter>
                </tr>
              </Table>
            </TableWrapper>
          </Col>
        </Row>
        <Block>
          <Heading2>Übersicht</Heading2>
          <Row>
            <Col>
              <TableOfContents>
                <li>
                  <a href="#vergleichstabelle">Vergleichstabelle</a>
                </li>
                <li>
                  <a href="#empfehlungen">
                    CBD Gleitgel Testbericht 2022/2023: TOP-Empfehlungen
                  </a>
                  <ol>
                    <li>
                      <ColoredLink color="#58cb53f7" href="#hohe-empfehlung">
                        Hohe CBD Gleitgel Empfehlung
                      </ColoredLink>
                    </li>
                    <li>
                      <ColoredLink color="#fdff61" href="#mittlere-empfehlung">
                        Mittlere CBD Gleitgel Empfehlung
                      </ColoredLink>
                    </li>
                    <li>
                      <ColoredLink color="#ec5d5d" href="#geringe-empfehlung">
                        Geringe bzw. keine CBD Gleitgel Empfehlung
                      </ColoredLink>
                    </li>
                  </ol>
                </li>
                <li>
                  <a href="#cbd-expertin">CBD Expertin</a>
                </li>
                <li>
                  <a href="#wissenswertes">Wissenswertes zu CBD Gleitgelen</a>
                  <ol>
                    <li>
                      <a href="#erste-tests">
                        Neu Cannabis Gleitgel 2022/2023, erste Tests und
                        Erfahrungen
                      </a>
                    </li>
                    <li>
                      <a href="#testkriterien">
                        Testkriterien vor dem Kauf von CBD Gleitgelen
                      </a>
                    </li>
                    <li>
                      <a href="#fazit">
                        Fazit Cannabis Gleitgel Testbericht: Erste Erfahrungen
                        sorgen bei Käuferinnen und Käufern für hohe Empfehlung
                      </a>
                    </li>
                    <li>
                      <a href="#maenner">CBD Gleitgel auch für Männer</a>
                    </li>
                  </ol>
                </li>
              </TableOfContents>
            </Col>
          </Row>
        </Block>
        <Block>
          <Heading2 id="empfehlungen" block>
            CBD Gleitgel Testbericht 2022/2023: TOP-Empfehlungen
          </Heading2>
          <Heading2 id="hohe-empfehlung" block small>
            Hohe CBD Gleitgel Empfehlung
          </Heading2>
          <WinnerBox align="stretch">
            <WinnerHighlight xs={12} md={6} lg={4} justify="center">
              <ContentWrapper>
                <ProductHeading rating={5}>
                  Testsieger CBD Gleitgel von Hanfgeflüster
                </ProductHeading>
                <TrackedLink
                  href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2Fjoy-cbd-gleitgel%2F"
                  category="cbd-gleitgel"
                  action="hanfgefluester"
                  label="bullet-image"
                >
                  <StaticImage
                    src="../../assets/images/products/cbd-gleitgel/joy.png"
                    alt="Joy CBD Gleitgel"
                    layout="constrained"
                    placeholder="blurred"
                    width={250}
                  />
                </TrackedLink>
                <p>🏅 Sehr gute CBD Wirkung</p>
                <p>👨🏼‍🔬 Test CBD Wirkgehalt durch TÜV SÜD</p>
                <p>💧 Beste Gleitfähigkeit ohne jegliche Schmerzen</p>
                <p>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2Fjoy-cbd-gleitgel%2F"
                    width={17}
                    category="cbd-gleitgel"
                    action="hanfgefluester"
                    label="bullet"
                  >
                    CBD Gleitgel kaufen
                  </ArrowButton>
                </p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={5} desktop>
                  Testsieger CBD Gleitgel von Hanfgeflüster
                </ProductHeading>
                <p>
                  Der Testsieger in unserem Vergleich ist das{" "}
                  <TrackedLink
                    href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2Fjoy-cbd-gleitgel%2F"
                    category="cbd-gleitgel"
                    action="hanfgefluester"
                    label="text"
                  >
                    Joy CBD Gleitgel von Hanfgeflüster
                  </TrackedLink>
                  . In unserem CBD Gleitgel Test überzeugte uns das Gleitgel vor
                  allem durch die <strong>sehr gute CBD Wirkung</strong>. Der
                  CBD Gehalt beträgt in nur einer einzelnen Flasche 50 mg. (Das
                  entspricht im Test, dass jeder Milliliter in der Flasche
                  mindestens einen Milligramm CBD enthält.) Die Qualität
                  wichtiger Prüfparameter wie der CBD Gehalt der Cannabis
                  Gleitgele werden von unabhängigen Laboren wie dem{" "}
                  <strong>
                    <a
                      href="https://www.tuvsud.com/de-de"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      TÜV Süd
                    </a>{" "}
                    zertifiziert
                  </strong>
                  . Das schätzen wir in unserem Cannabis Gleitgel Test sehr, da
                  es eine deutsche Prüforganisation ist. Das Cannabis Gleitgel
                  ist aus <strong>Wasserbasis und eignet sich für</strong> alle
                  intimen Zwecke wie{" "}
                  <strong>
                    Vaginalverkehr, Analverkehr, Oralverkehr und für erotische
                    Massagen
                  </strong>
                  . Das Gleitgel ist <strong>geschmack- und geruchlos</strong>,
                  um nicht von zärtlichen Momenten abzulenken. Das bedeutet,
                  dass der typische bzw. teilweise unangenehme Geschmack von CBD
                  bei den Gleitgelen nicht zu schmecken ist. Das Gleitgel von
                  Hanfgeflüster ist{" "}
                  <strong>
                    dermatologisch getestet und durch die pH-Optimierung
                    verträglich für jeden Hauttypen
                  </strong>
                  . Bedeutende Vorteile von CBD Gleitgelen beim Sex für Mann und
                  Frau erfahren Sie in unserem{" "}
                  <Link to="/ratgeber/cbd-gleitgel/">Blogbeitrag</Link>.
                </p>
                <p>
                  Das CBD Gleitgel von Hanfgeflüster ist außerdem{" "}
                  <strong>vegetarisch und vegan</strong>, d.h. ohne Bestandteile
                  tierischen Ursprungs. Dadurch hat das Cannabis Gleitgel von
                  Hanfgeflüster einen zusätzlichen Wettbewerbsvorteil im
                  Vergleich zu anderen Mitbewerbern in unserem Test. Die{" "}
                  <strong>recycelten Verpackungsmaterialien</strong> des
                  Gleitgels runden das Umweltbewusstsein ab. Um den Cannabis
                  Gleitgel Test mit anderen Anbietern vergleichbar zu machen,
                  beinhaltete eine <strong>Packungsgröße 50 ml</strong>. Das
                  sind über 25 Verwendungen, weil das Gleitgel sehr gleitfähig
                  ist. Schon bei der ersten Anwendung spüren Sie die bessere
                  Gleitfähigkeit, sodass eine Flasche insgesamt sehr ergiebig
                  sein kann. Trotz der sehr guten Qualität ist das CBD Gleitgel
                  im Test am günstigsten.{" "}
                  <strong>Das Joy CBD Gleitgel kostet lediglich 19,95 €</strong>
                  . Mit einer garantiert{" "}
                  <strong>schnellen Lieferung innerhalb von 1-2 Tagen</strong>{" "}
                  in Deutschland ist der Einkauf bei Hanfgeflüster ab 30,00 €
                  sogar <strong>versandkostenfrei</strong>. Zusammengefasst
                  empfehlen wir das{" "}
                  <TrackedLink
                    href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2Fjoy-cbd-gleitgel%2F"
                    category="cbd-gleitgel"
                    action="hanfgefluester"
                    label="text"
                  >
                    CBD Gleitgel von Hanfgeflüster
                  </TrackedLink>{" "}
                  mit 5 von 5 Sternen in unserem Cannabis Gleitgel Test als{" "}
                  <strong>Leistungssieger</strong> weiter. Zudem ist das CBD
                  Gleitgel verglichen mit anderen Anbietern im Test der{" "}
                  <strong>Preissieger</strong>.
                </p>
                <Paragraph right>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2Fjoy-cbd-gleitgel%2F"
                    category="cbd-gleitgel"
                    action="hanfgefluester"
                    label="text-button"
                    width={18}
                  >
                    Weiter informieren
                  </ArrowButton>
                </Paragraph>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
        <Block>
          <Heading2 block>CBD Gleitgele im Test & Vergleich 2022/2023</Heading2>
          <Heading2 id="mittlere-empfehlung" block small>
            Mittlere CBD Gleitgel Empfehlung
          </Heading2>
          <WinnerBox align="stretch" yellow>
            <WinnerHighlight xs={12} md={6} lg={4} justify="center" yellow>
              <ContentWrapper>
                <ProductHeading rating={4}>
                  Alternatives CBD Gleitgel über Amazon
                </ProductHeading>
                <TrackedLink
                  href="https://www.amazon.de/Pharmquest-Shots-Cannabis-Gleitgel-Wasserbasis/dp/B07QWLFFTH"
                  category="cbd-gleitgel"
                  action="shots"
                  label="bullet-image"
                >
                  <StaticImage
                    src="../../assets/images/products/cbd-gleitgel/shots.png"
                    alt="Shots CBD Gleitgel"
                    layout="constrained"
                    placeholder="blurred"
                    width={250}
                  />
                </TrackedLink>
                <p>✔️ Verkauf über Amazon</p>
                <p>🎁 Attraktives Verpackungsdesign</p>
                <p>❓ CBD Gehalt unbekannt</p>
                <p>
                  <ArrowButton
                    href="https://www.amazon.de/Pharmquest-Shots-Cannabis-Gleitgel-Wasserbasis/dp/B07QWLFFTH"
                    width={17}
                    category="cbd-gleitgel"
                    action="shots"
                    label="bullet"
                  >
                    CBD Gleitgel kaufen
                  </ArrowButton>
                </p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={2.5} desktop>
                  Alternatives CBD Gleitgel über Amazon
                </ProductHeading>
                <p>
                  Eine <strong>mittelmäßige Empfehlung</strong> des CBD
                  Gleitgels im Test sind die &bdquo;
                  <strong>
                    <TrackedLink
                      href="https://www.amazon.de/Pharmquest-Shots-Cannabis-Gleitgel-Wasserbasis/dp/B07QWLFFTH"
                      category="cbd-gleitgel"
                      action="shots"
                      label="text"
                    >
                      Shots PHA139 CBD Gleitmittel
                    </TrackedLink>
                  </strong>
                  &ldquo; von Shots bv. <strong>aus den Niederlanden</strong>.
                  Der Anbieter verkauft seine Cannabis Gleitgele{" "}
                  <strong>über Amazon</strong>. Es besteht aus{" "}
                  <strong>Wasserbasis</strong> und in einer Tube sind 50 ml
                  Inhalt enthalten. Aus den Angaben auf der Amazon Website geht
                  leider nicht hervor, wieviel Milligramm CBD in einer Tube
                  enthalten sind. Es gibt zu dieser wichtigen Anforderung leider
                  keine Angaben. Auch auf der Verpackung geht diese Info nicht
                  hervor, dennoch enthält das Gleitgel CBD. Die subjektive{" "}
                  <strong>
                    CBD Wirkung bewerten Testerinnen und Tester als mittelmäßig
                  </strong>{" "}
                  im Vergleich zu anderen CBD Gleitgelen. Auch den Geschmack
                  beurteilten mehrere Probanden in unserem Cannabis Gleitgel
                  Test als <strong>teils bitter</strong>. Das Gleitgel ist wie
                  der Testsieger ebenfalls geruchlos und eignet sich{" "}
                  <strong>
                    für Vaginal-, Anal- und Oralverkehr sowie für Massagen
                  </strong>
                  . Jedoch ist das{" "}
                  <strong>CBD Gleitgel sehr dünnflüssig</strong>. Es empfiehlt
                  sich, das Gel am besten in der Hand zu verreiben, bevor es auf
                  die jeweiligen Stellen aufgetragen wird.
                </p>
                <p>
                  Für das wichtige Kriterium CBD Zertifikat in unserem CBD
                  Vergleich liegen <strong>keine Analysenzertifikate</strong>{" "}
                  oder ähnliche Angaben von unabhängigen Prüforganisationen vor.
                  Die Analysenzertifikate oder Auskünfte dazu waren während des
                  Untersuchungszeitraums für den CBD Gleitgel Test nicht auf der
                  Website des Anbieters bzw. des Zwischenhändlers vorhanden.
                </p>
                <p>
                  Mit einem{" "}
                  <strong>Preis von 24,16 € inklusive Versandkosten</strong> bei
                  einer Lieferzeit innerhalb von 3-5 Tagen liegt das CBD
                  Gleitgel in unserem Test aller Produkte im mittleren Bereich.
                  Insgesamt erhält das Cannabis Gleitgel eine{" "}
                  <strong>Empfehlung mit 2,5 von 5 Sternen</strong> in dem CBD
                  Gleitgel Test.
                </p>
                <Paragraph right>
                  <ArrowButton
                    href="https://www.amazon.de/Pharmquest-Shots-Cannabis-Gleitgel-Wasserbasis/dp/B07QWLFFTH"
                    width={18}
                    category="cbd-gleitgel"
                    action="shots"
                    label="text-button"
                  >
                    Weiter informieren
                  </ArrowButton>
                </Paragraph>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
        <Block>
          <Heading2 block>
            CBD Gleitgel Test-Übersicht • Produkte im Vergleich
          </Heading2>
          <Heading2 id="geringe-empfehlung" block small>
            Geringe bzw. keine CBD Gleitgel Empfehlung
          </Heading2>
          <WinnerBox align="stretch" red>
            <WinnerHighlight xs={12} md={6} lg={4} justify="center" red>
              <ContentWrapper>
                <ProductHeading rating={2}>
                  Schlechtes CBD Gleitgel
                </ProductHeading>
                <StaticImage
                  src="../../assets/images/products/cbd-gleitgel/mian.png"
                  alt="Mian CBD Gleitgel"
                  layout="constrained"
                  placeholder="blurred"
                  width={250}
                />
                <p>❌ Schlechte CBD Wirkung</p>
                <p>❌ CBD Gleitgel trocknet schnell </p>
                <p>❌ Sehr klebrig</p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={1} desktop>
                  Schlechtes CBD Gleitgel
                </ProductHeading>
                <p>
                  Besonders schlecht schneidet in unserem CBD Gleitgel Test das
                  Cannabis Gleitgel von einem Amazon Händler aus China ab.
                  Ungenügend waren vor allem die <strong>schlechte</strong>{" "}
                  Wirkung bzw. der nicht vorhandene Wirkeffekt bei einem{" "}
                  <strong>niedrigen CBD Gehalt von 10 mg CBD</strong> in einer
                  Tube mit 50 ml. Das Gleitgel ist zwar auf Wasserbasis, aber{" "}
                  <strong>schlechte Erfahrungen</strong> machten Käuferinnen und
                  Käufer außerdem bei der Hautverträglichkeit. Bei einigen
                  Anwendungen führte es zu{" "}
                  <strong>Rötungen auf der Haut</strong>. In einigen Rezensionen
                  heißt es zudem, dass das CBD Gleitgel{" "}
                  <strong>stark bitter</strong> schmeckt und daher beim
                  Oralverkehr nicht in Berührung kommen sollte.
                </p>
                <p>
                  Das Cannabis Gleitgel eignet sich somit vorwiegend für den
                  Vaginal- und Analverkehr sowie ggf. für Massagen. Unsere
                  Untersuchungen zeigen jedoch, dass das Cannabis{" "}
                  <strong>Gleitgel schnell trocknet</strong> und das führt dazu,
                  dass es <strong>schnell klebrig</strong> wird.
                  Analysenzertifikate von unabhängigen Laboren, die
                  beispielsweise den CBD Gehalt, der für die CBD Wirkung
                  entscheidend ist, analysiert haben, gibt es nicht.
                </p>
                <p>
                  Mit einem <strong>sehr hohen Preis von 25,99 €</strong> (exkl.
                  Versandkosten) und einer durchschnittlichen{" "}
                  <strong>Lieferzeit von zwölf Tagen</strong> unterscheidet sich
                  das CBD Gleitgel in dem Test deutlich von anderen Anbietern
                  und so vor allem von dem CBD Gleitgel Testsieger.
                </p>
                <p>
                  Als Fazit in dem CBD Vergleich ziehen wir, dass unsere
                  Erfahrungen und Testergebnisse von einer Empfehlung für den{" "}
                  <strong>Kauf des CBD Gleitgels abraten</strong>. Wir bewerten
                  das CBD Gleitgel schlussendlich mit einem von fünf Sternen.
                </p>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
      </Container>
      <BackgroundBeige>
        <Container>
          <Block mt={40}>
            <DivHelper center>
              <Heading2 id="cbd-expertin">CBD Expertin</Heading2>
            </DivHelper>
            <Row>
              <Col md={6}>
                <Quote>
                  &bdquo;Hey! Ich arbeite bei cbd-vergleiche.de und gemeinsam
                  mit meinem Team haben wir uns dem neuesten Produkt auf dem CBD
                  Markt, den CBD Gleitgelen, gewidmet. Wir haben die innovativen
                  Gleitgele untersucht und miteinander verglichen. Viel Spaß
                  beim Informieren und gutes Gelingen beim Kauf des ersten CBD
                  Gleitgels!&ldquo;
                </Quote>
                <SeparatorLine small />
                <Row align="center">
                  <Col xs={4} md={5} lg={4} xl={5}>
                    <Quote>Lisa Koch (28)</Quote>
                  </Col>
                  <Col xs={8} md={7} lg={8} xl={7}>
                    <ImageWrapper>
                      <StaticImage
                        src="../../assets/images/team/lisa-koch.jpg"
                        placeholder="blurred"
                        layout="constrained"
                        width={300}
                        alt="Lisa Koch (CBD Expertin)"
                        style={{ borderRadius: "50%" }}
                      />
                    </ImageWrapper>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Heading3Margin>Meine persönliche Empfehlung</Heading3Margin>
                <p>
                  Für den ersten Kauf von Gleitgelen mit CBD empfehle ich das{" "}
                  <TrackedLink
                    href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2Fjoy-cbd-gleitgel%2F"
                    category="cbd-gleitgel"
                    action="hanfgefluester"
                    label="expert"
                  >
                    CBD Gleitgel Joy von Hanfgeflüster
                  </TrackedLink>{" "}
                  weiter. Es überzeugt mich vor allem durch den{" "}
                  <strong>hohen CBD Gehalt von 50 mg</strong> CBD in einer
                  Flasche mit 50 ml Gleitgel. Dadurch wird der Wirkeffekt des
                  CBDs definitiv garantiert und der intime Moment wird noch
                  intensiver. Außerdem empfehle ich das Joy CBD Gleitgel von
                  Hanfgeflüster weiter, weil es eine{" "}
                  <strong>sehr gute Gleitfähigkeit</strong> hat. Es besteht aus
                  Wasserbasis und eignet sich daher hervorragend für den
                  Vaginal- und Analverkehr und das schon bei der ersten
                  Anwendung. Zu guter Letzt ist das CBD Gleitgel Joy günstig zu
                  kaufen. Es kostet <strong>nur 19,95 €</strong>. Verglichen mit
                  anderen Anbietern, vor allem über Amazon, kann man also viel
                  Geld sparen. Für mich daher eine absoluter Geheimtipp mit
                  klarer Weiterempfehlung.
                </p>
              </Col>
            </Row>
            <RowMargin>
              <Col>
                <h3>Meine 3 wichtigsten Kriterien</h3>
              </Col>
            </RowMargin>
            <RowMargin margin={10}>
              <Col xs={12} xl={4}>
                <h4>1. CBD Gehalt für den Wirkeffekt</h4>
                <p>
                  Gleitgele mit dem Zusatz von CBD sind keine herkömmlichen
                  Gleitgele. Es ist etwas Neues, etwas anderes auf dem Markt für
                  Gleitgele. Der Wirkstoff CBD von der Hanfpflanze wird dabei in
                  das Gleitgel eingebracht und hat eine{" "}
                  <strong>
                    positiv entspannende und intensivere Wirkung auf den
                    Gefühlszustand
                  </strong>
                  . Genauere Informationen zu dem Effekt von CBD in Gleitgelen
                  erfahren Sie in unserem aktuellsten{" "}
                  <Link to="/ratgeber/cbd-gleitgel/">Ratgeberbeitrag</Link>.
                  Eine{" "}
                  <strong>
                    ausreichende Menge an CBD ist daher notwendig, damit das CBD
                    seinen Sinn und Zweck erfüllt
                  </strong>
                  . Aus diesem Grund ist eines der wichtigsten Kriterien für die
                  Bewertung im CBD Gleitgel Test der Gehalt an CBD und die
                  daraus resultierende Wirkung.
                </p>
              </Col>
              <ColMarginMobile xs={12} xl={4}>
                <h4>2. Gleitfähigkeit</h4>
                <p>
                  Wenn es um das Thema Gleitgele geht, gibt es einen
                  hauptsächlichen Nutzen für ihren Einsatz. Die Hauptfunktion
                  von Gleitgelen mit oder ohne CBD ist selbstverständlich die{" "}
                  <strong>
                    bessere Gleitfähigkeit beim Vaginal- oder Analverkehr, aber
                    nicht jedes Gleitgel gleitet gleich gut
                  </strong>
                  . Auch wenn es die Hersteller in ihren Produktbeschreibungen
                  oftmals versprechen. Gleitgele, ob mit oder ohne CBD,
                  unterscheiden sich in ihrer Gleitfähigkeit zum Teil stark
                  voneinander. Daher ist es ebenfalls ein entscheidendes
                  Kriterium für mich.
                </p>
              </ColMarginMobile>
              <ColMarginMobile xs={12} xl={4}>
                <h4>3. Preis</h4>
                <p>
                  Was kosten CBD Gleitgele? Diese Frage stellen sich womöglich
                  die Meisten und das ist auch für mich ein sehr wichtiges
                  Kriterium. Auch wenn Gleitgele mit CBD neu auf dem Markt sind
                  und dadurch aktueller denn je sind,{" "}
                  <strong>
                    sollte der Preis nicht außer Acht gelassen werden
                  </strong>
                  . Sicherlich kann man viel Geld für neue Produkte wie CBD
                  Gleitgele ausgeben, aber dabei müssen{" "}
                  <strong>teure Varianten nicht immer die Besten sein</strong>.
                  Selbst günstige Gleitgele mit CBD können durch einen klugen
                  Vergleich und clevere Recherchen besser sein als teurere
                  Konkurrenzprodukte.
                </p>
              </ColMarginMobile>
            </RowMargin>
          </Block>
        </Container>
      </BackgroundBeige>
      <Container>
        <Block mt={100}>
          <DivHelper center>
            <Heading2 id="wissenswertes">
              Wissenswertes zu CBD Gleitgelen
            </Heading2>
          </DivHelper>
        </Block>
        <Block>
          <Heading2 id="neu-test">
            Neu Cannabis Gleitgel 2022/2023, erste Tests und Erfahrungen
          </Heading2>
          <p>
            Es ist schnell zu erkennen, dass die{" "}
            <strong>Auswahl an CBD Gleitgelen übersichtlich</strong> ist. Das
            liegt daran, dass Gleitmittel mit dem Wirkstoff CBD relativ{" "}
            <strong>
              neu auf dem Markt sind und deswegen noch wenige Anbieter
            </strong>{" "}
            existieren. Auch gibt es bislang keine bzw. wenige
            Vergleichsberichte in dem Bereich. Erfahrungen bzw. Rezensionen von
            anderen Kunden sind überschaubar. Fakt ist dennoch, dass uns CBD
            selbst in Gleitmitteln dabei unterstützen kann, zur Ruhe zu kommen
            und uns hinzugeben.{" "}
            <strong>
              Es verschafft ein sehr angenehmes Gefühl und Berührungen werden
              intensiver wahrgenommen
            </strong>
            . Genauere wissenschaftlich belegte Informationen sowie die
            medizinische Wirkweise von CBD Gleitmitteln erhalten Sie in unserem
            Ratgeber Artikel{" "}
            <Link to="/ratgeber/cbd-gleitgel/">
              CBD Gleitgel: Das Upgrade für Ihr Liebesleben
            </Link>
            .
          </p>
          <p>
            Auch wenn die Gleitgele mit CBD neu sind, haben wir uns von
            cbd-vergleiche.de bereits jetzt schon mit dem Thema beschäftigt und
            drei gängige CBD Gleitgele herangezogen, um sie kritisch zu
            analysieren.
          </p>
        </Block>
        <Block>
          <Row align="center" justify="center">
            <Col xs={12}>
              <Heading2 id="testkriterien">
                Testkriterien vor dem Kauf von CBD Gleitgelen
              </Heading2>
              <p>
                Dabei unterscheiden wir die{" "}
                <strong>Prüfkriterien in drei wesentliche Bereiche</strong>. Zum
                einen der <strong>Preis und die inhaltlichen Merkmale</strong>{" "}
                und zum anderen die{" "}
                <strong>Eignungen und die Verträglichkeit</strong>. Außerdem
                geben wir <strong>weitere zusätzliche Informationen</strong>,
                die Ihnen bei dem Kauf von CBD Gleitgelen wichtig sein können.
                Auf die genauen Zuordnungen der Prüfparameter in die einzelnen
                Bereiche gehen wir im Folgenden näher ein.
              </p>
              <p>
                Zu dem Spektrum <strong>Preis und Inhalt</strong> gehören neben
                den <strong>Kosten</strong> wichtige Kriterien wie die{" "}
                <strong>Füllmenge pro Flasche</strong> sowie{" "}
                <strong>der CBD Gehalt pro Flasche</strong> und die{" "}
                <strong>Konsistenz bzw. die Basis</strong> des Gleitmittels wie
                Wasser oder Silikon.
              </p>
            </Col>
            <ColMargin xs={12} lg={7} mt={20} mb={20}>
              <p>
                In das Spektrum <strong>Eignungen und Verträglichkeit</strong>{" "}
                fallen wichtige Merkmale wie die Eignung für{" "}
                <strong>
                  Analverkehr, Oralverkehr, Vaginalverkehr und für Massagezwecke
                </strong>
                . Wir bewerten zudem den{" "}
                <strong>subjektiven Wirkeffekt des Gleitgels mit CBD</strong>.
                Darüber hinaus zählen hierzu wichtige Eigenschaften wie die{" "}
                <strong>
                  Verträglichkeit mit Kondomen und Sex Toys, die Verträglichkeit
                  auf der Haut (pH-optimiert)
                </strong>
                , die <strong>Geruchs- und Geschmackseinstufungen</strong> der
                CBD Gleitgele sowie die Prüfung auf eine{" "}
                <strong>vegane Rezeptur</strong>. An dieser Stelle weisen wir
                darauf hin, dass CBD Gleitgele sich ausdrücklich von CBD Ölen
                unterscheiden. Theoretisch könnte man CBD Öl mit einem
                herkömmlichen Gleitgel vermischen und so sein eigenes CBD
                Gleitmittel herstellen. Das ist jedoch ein Trugschluss, denn
                klassische CBD Öle wie unsere{" "}
                <Link to="/vergleiche/cbd-oel/">CBD Öl Testsieger</Link> dürfen
                nicht mit Kondomen benutzt werden, da die Kondome dadurch porös
                werden können. Spezielle CBD Gleitgele können aufgrund ihrer{" "}
                <strong>
                  differenzierten Verfahrensweise in der Herstellung
                </strong>{" "}
                dafür in der Regel bedenkenlos mit Kondomen und Sex Toys
                verwendet werden.
              </p>
            </ColMargin>
            <ColMargin xs={12} sm={10} md={6} lg={5} mb={20}>
              <StaticImage
                src="../../assets/images/stock/gleitgel-banane.jpg"
                placeholder="blurred"
                layout="constrained"
                width={800}
                alt="CBD Gleitgel auf einer Banane"
              />
            </ColMargin>
            <Col xs={12}>
              <p>
                In den <strong>zusätzlichen Informationen</strong> führen wir
                wichtige Aspekte wie die <strong>Lieferzeit</strong> in Tagen
                nach Bestellung des CBD Gleitgels im Online-Shop sowie die{" "}
                <strong>Lieferkosten</strong> auf. Außerdem prüfen wir das
                Vorhandensein von <strong>Zertifizierungen</strong> des
                Anbieters für seine CBD Produkte durch ein externes Prüflabor{" "}
                <strong>wie TÜV Süd</strong> oder andere offizielle
                Zertifizierungsstellen. Aus derzeit aktuellen und ökologischen
                Anlässen führen wir zusätzlich das Kriterium{" "}
                <strong>Verpackungsart</strong> auf. Der Vergleich der CBD
                Gleitgele wird durch die Auflistung weiterer Besonderheiten wie
                die <strong>Gleitfähigkeit</strong> oder{" "}
                <strong>dermatologische Testungen</strong> beendet, bevor zum
                Schluss eine grundsätzliche{" "}
                <strong>Weiterempfehlung bzw. Bewertung in Sternen</strong> von
                cbd-vergleiche.de erfolgt.
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Heading2 id="fazit">
            Fazit Cannabis Gleitgel Testbericht: Erste Erfahrungen sorgen bei
            Käuferinnen und Käufern für hohe Empfehlung
          </Heading2>
          <p>
            Auch wenn es noch nicht viele Erfahrungen zu CBD Gleitgelen gibt,
            sprechen die ersten <strong>Erfahrungsberichte</strong> jetzt schon
            für sich.{" "}
            <strong>
              Gleitmittel mit CBD sollten sowohl Frauen und Männer mal
              ausprobieren
            </strong>
            , um ganz eigene Erfahrungen zu sammeln. Aufgrund der positiven
            Auswirkungen von CBD auf den Gemütszustand empfehlen wir als
            CBD-Experten Gleitgele mit CBD weiter und das sogar für die
            intimsten Momente. Unser CBD Gleitgel Test unterstützt Sie dabei,
            das <strong>richtige CBD Gleitgel zu kaufen</strong>, um schon bei
            der ersten Anwendung vom besten Produkt Gebrauch zu machen und gute
            Erfahrungen zu sammeln. Sollten Sie, wie wir, begeistert und
            zufrieden sein von den positiven Effekten des CBD Gleitgels, zögern
            Sie nicht und empfehlen Sie das CBD Gleitgel gerne an Ihren
            Freunds-, Bekannten- oder Kollegenkreis weiter, um noch mehr
            Bekanntheit für tolle Innovation wie diese zu schaffen.
          </p>
        </Block>
        <Block>
          <Row align="center" justify="center">
            <Col xs={12} lg={7}>
              <Heading2 id="maenner">CBD Gleitgel auch für Männer</Heading2>
              <p>
                Trotz der entspannenden Wirkung von CBD im Gleitgel können auch
                Männer davon profitieren. Zunächst einmal kommt das CBD Gleitgel
                bei einem{" "}
                <strong>geschützten Geschlechtsverkehr durch das Kondom</strong>{" "}
                nicht mit dem Penis in Berührung.{" "}
                <strong>
                  Auch wenn der Penis bewusst mit dem CBD Gleitgel eingeschmiert
                  wird, führt das nicht zur sofortigen Erschlaffung. Die
                  Erregung des Penis bleibt erhalten
                </strong>{" "}
                und das CBD führt zusätzlich zu einem angenehmen Gefühl, weil
                das CBD über die Eichel aufgenommen wird. Die Wirkung von CBD
                tritt nie sofort ein. In der Regel dauert es mindestens 20
                Minuten.{" "}
              </p>
              <p>
                Beim Analverkehr wird das CBD bei beiden Geschlechtern
                gleicherweise aufgenommen. Weitere Infos dazu können Sie gerne
                in unserem{" "}
                <Link to="/ratgeber/cbd-gleitgel/">
                  medizinischen Blogbeitrag
                </Link>{" "}
                nachlesen.
              </p>
            </Col>
            <Col xs={12} sm={10} md={6} lg={5}>
              <DivHelper mt={20}>
                <StaticImage
                  src="../../assets/images/stock/aubergine.jpg"
                  placeholder="blurred"
                  layout="constrained"
                  width={800}
                  alt="Aubergine"
                />
              </DivHelper>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <DateRight>
                <em>Beitrag veröffentlicht am 06.11.2022.</em>
              </DateRight>
            </Col>
          </Row>
        </Block>
      </Container>
    </Layout>
  )
}

export default CbdGleitgel
